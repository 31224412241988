<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="huiYuanDJ" label="会员等级" />
            <x-detail-item prop="huiYuanMC" label="会员名称" />
            <x-detail-item prop="zuiDiJiFen" label="最低积分" />
            <x-detail-item prop="xiangShouZheKou" label="享受折扣" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/HuiYuanDJ";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                huiYuanDJ: "", // 会员等级
                huiYuanMC: "", // 会员名称
                zuiDiJiFen: "", // 最低积分
                xiangShouZheKou: "", // 享受折扣
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>