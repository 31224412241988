<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="会员等级" prop="huiYuanDJ" class="w50">
                <el-input v-model="form.huiYuanDJ" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="会员名称" prop="huiYuanMC" class="w50">
                <el-input v-model="form.huiYuanMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="最低积分" prop="zuiDiJiFen" class="w50">
                <el-input v-model.number="form.zuiDiJiFen" size="small" maxlength="" show-word-limit/>
            </el-form-item>
            <el-form-item label="享受折扣" prop="xiangShouZheKou" class="w50">
                <el-input v-model="form.xiangShouZheKou" size="small" maxlength="8" placeholder=" 例:95折，请输入:0.95" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/HuiYuanDJ";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';

    export default {
        mixins: [XEditBase],
        beforeCreate() {
            let {required, positiveZeroTwo, digits} = ruleBuilder;
            this.rules = {
                huiYuanDJ: [required()], // 会员等级
                huiYuanMC: [required()], // 会员名称
                zuiDiJiFen: [
                    { required: true, message: '请输入会员等级所需最低积分', trigger: 'blur' },
                    { type: 'number', message: '会员等级所需最低积分必须为数字值'}
                ], // 最低积分
                xiangShouZheKou: [required(),positiveZeroTwo()], // 享受折扣
            }
            this.titlePrefix = '会员等级';
            this.defaultForm = {
                id: null,
                huiYuanDJ: '', // 会员等级
                huiYuanMC: '', // 会员名称
                zuiDiJiFen: 0, // 最低积分
                xiangShouZheKou: '', // 享受折扣
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>